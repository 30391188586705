<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Parâmetros da Assinatura</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <form class="needs-validation">
        <card>
          <h3 slot="header" class="mb-0">
            <i class="fas fa-money-check mr-2"></i> Parâmetros da Assinatura
          </h3>
              
            <div class="row">
              <div class="col-12">
                <label class="form-control-label">
                  Assinatura por falta de pagamento será 
                </label>
                <!--
                  <div class="row">
                  <div class="col-2 custom-switch ml-2 campoForm">
                    <input
                      type="checkbox"
                      v-model="param.isSuspend"
                      class="custom-control-input"
                      id="check2"/>
                    <label class="custom-control-label" for="check2">Suspensa</label>
                  </div>
                  <div class="col-4 campoForm">
                    <small>Depois de</small>
                    <base-input
                      class="mt-3 ml-1 col-sm-6"
                      v-mask="['####']"
                      placeholder="Quantidade de dias"
                      v-model="param.daysDueSuspend"
                    ></base-input>        
                    <small>dias</small>            
                  </div>
                </div>
                -->
                <div class="row mt-1">
                  <div class="col-2 custom-switch ml-2 campoForm">
                    <input
                      type="checkbox"
                      v-model="param.isBlock"
                      class="custom-control-input"
                      id="check10"/>
                    <label class="custom-control-label" for="check10">Bloqueada</label>
                  </div>
                  <div class="col-4 campoForm">
                    <small>Depois de</small>
                    <base-input
                      class="mt-3 ml-1 col-sm-6"
                      v-mask="['####']"
                      placeholder="Quantidade de dias"
                      v-model="param.daysDueBlock"
                    ></base-input>      
                    <small>dias</small>                  
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4">
              <base-button type="primary" @click="enviar()">
                <i class="fas fa-money-check mr-2"></i>Salvar
              </base-button>
            </div>
        </card>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.loadParams();
  },
  data() {
    return {
      param: {
        isBlock: false,
        isSuspend: false,
        daysDueBlock: 0,
        daysDueSuspend: 0,
      },
    };
  },
  methods: {
    loadParams() {
      this.$clubApi
        .get("/params/subscription")
        .then((response) => {
          this.param = response.data.object;
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "Erro ao carregar parametros da assinatura",
          });
        })
        .finally(() => {
          NProgress.done();
        });
    },
    async enviar() {
      if (this.param.daysDueBlock == "") {
        this.$notify({
          type: "warning",
          message: "Informe os dias de vencimento para o bloqueio da assinatura.",
        });
        return false;
      }
      if (this.param.daysDueBlock < 0) {
        this.$notify({
          type: "warning",
          message: "Dias de vencimento para o bloqueio da assinatura inválido.",
        });
        return false;
      }

      let confirmacao = true;
      // a confirmação só aparece se a opção de bloquear estiver selecionada
      if (this.param.isBlock) {
        const options = {title: 'Alteração do parâmetro', okLabel: 'Sim', cancelLabel: 'Não'}
        await this.$dialogs.confirm("Essa ação ira blolquear todas as assinaturas com faturas com mais de "+ this.param.daysDueBlock +" dias de vencimento, deseja continuar?", options)  
          .then(res => {
            confirmacao =  res.ok;
          });
      }
      if (confirmacao) {
        this.beginSaveProcess = true;
        this.$clubApi.post("/params/subscription/save", this.param).then(() => {
        this.$notify({
          type: "success",
            message: "Parâmetros salvos com sucesso.",
          });
        }).catch((error) => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        }).finally(() => {
          this.beginSaveProcess = false;
          NProgress.done();
        });        
      }
    },
  },
};
</script>
<style>
.campoForm {
  flex-direction: row;
  display: flex;
  align-items: center;
}
</style>